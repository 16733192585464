import { createRouter, createWebHistory } from 'vue-router'
import SitePageA from '@/components/SitePageA.vue'
import SitePageB from '@/components/SitePageB.vue'
import SitePageC from '@/components/SitePageC.vue'

const routes = [
  {
    path: '/',
    name: 'routerTestA',
    component: SitePageA
  },
  {
    path: '/page_b',
    name: 'routerTestB',
    component: SitePageB
  },
  {
    path: '/page_c',
    name: 'routerTestC',
    component: SitePageC
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
