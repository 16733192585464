<template>
    <div class="header-base">
      <div class="header-base__content">
        実験
      </div>
    </div>
</template>

<script></script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}

.header-base {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  &__content {
    background: #bababa;
    width: 100%;
    max-width: 600px;
    height: 64px;
    margin-left: auto;
    margin-right: auto;
  }
}

</style>