<template>
    <router-view />
</template>

<script></script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}

</style>