<template>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <div>
    <h2>テストページA</h2>
    <router-link to="/page_b">ページB</router-link>
  </div>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
</template>

<script>
export default {
  name: "SitePageA",
};
</script>

<style lang="scss">
.header {
  background-color: #e2e2e2;
  padding: 10px;
}
</style>

