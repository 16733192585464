<template>
    <div class="footer-base">
      <div class="footer-base__content">
        <p class="footer-base__content__item1"><router-link to="/">PageA</router-link></p>
        <p class="footer-base__content__item2"><router-link to="/page_b">PageB</router-link></p>
        <p class="footer-base__content__item2"><router-link to="/page_c">PageC</router-link></p>
        <p class="footer-base__content__item3">サンプル</p>
        <p class="footer-base__content__item4">サンプル</p>
        <p class="footer-base__content__item5">サンプル</p>
      </div>
    </div>
</template>

<script></script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}

.footer-base {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: space-between;

  &__content {
    height: 64px;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    background-color: #bababa;

    &__item1 {
      float: left;
      min-width: 20%;
      min-height: 64px;
      background-color: #9b9b9b;
      text-align:center;
      line-height: 64px; // 1行の高さを64にすることで中央とする
    }

    &__item2 {
      float: left;
      min-width: 20%;
      min-height: 64px;
      background-color: #e0e0e0;
      text-align:center;
      line-height: 64px; // 1行の高さを64にすることで中央とする
    }

    &__item3 {
      float: left;
      min-width: 20%;
      min-height: 64px;
      background-color: #c7c7c7;
      text-align:center;
      line-height: 64px; // 1行の高さを64にすることで中央とする
    }

    &__item4 {
      float: left;
      min-width: 20%;
      min-height: 64px;
      background-color: #949494;
      text-align:center;
      line-height: 64px; // 1行の高さを64にすることで中央とする
    }

    &__item5 {
      float: left;
      min-width: 20%;
      min-height: 64px;
      background-color: #b6b6b6;
      text-align:center;
      line-height: 64px; // 1行の高さを64にすることで中央とする
    }
  }
}

</style>