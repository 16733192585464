<template>
  <v-app>
    <v-main>
      <SmartApp/>
    </v-main>
  </v-app>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import SiteMain from './components/SiteMain.vue'
import SmartApp from './components/SmartApp.vue'

export default {
  name: 'App',

  components: {
    SmartApp,
  },

  data: () => ({
    //
  }),
}
</script>
