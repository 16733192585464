<template>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <div>
    <h2>テストページB</h2>
    <router-link to="/page_c">ページC</router-link>
  </div>
  <div class="example">
    <MyCanvas :radius="radius"/>
    <p><input type="range" min="0" max="100" v-model.number="radius"></p>
  </div>
</template>

<script>
// キャンバス用コンポーネントの読み込み
import MyCanvas from './MyCanvas.vue'
export default {
  name: "SitePageB",
  components: {
    MyCanvas
  },
  data() {
    return {
      radius: 50
    }
  }
}
</script>

<style lang="scss">

html,
body {
  margin: 0;
  padding: 0;
  background-color: #ddffcd;
}

</style>

  