<template>
  <v-app class="smart-app">
    <SiteHeader>  
    </SiteHeader> 
    <div class="smart-app__main-content">
      <SiteMain></SiteMain> 
    </div>
    <SiteFooter>  
    </SiteFooter> 
  </v-app>
</template>

<script>
import SiteHeader from "./SiteHeader.vue";
import SiteMain from "./SiteMain.vue";
import SiteFooter from "./SiteFooter.vue";

export default {
  components: {
    SiteHeader,
    SiteMain,
    SiteFooter,
  },
  data: () => ({
  }),
};
</script>

<style lang="scss">
html,
body {
  margin: 0;
  padding: 0;
}

.smart-app {

  background-color: #808080;

  &__main-content {
    width: 100%;
    max-width: 600px;
    min-height: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #ffffff;
  }
}
</style>