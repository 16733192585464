<template>
  <br/>
  <br/>
  <br/>
  <br/>
  <br/>
  <div>
    <h2>テストページC</h2>
    <router-link to="/">ページA</router-link>
  </div>
</template>

<script>
export default {
  name: "SitePageB",
};
</script>

<style lang="scss">

html,
body {
  margin: 0;
  padding: 0;
  background-color: #ddffcd;
}

</style>

  